<template>
  <div class="hk-content">
    <div class="hk-detail">
      <div class="hk-detail__preview" @click="previewShow" v-model="getUpdate">
        <hk-pic-zoom
          class="hk-desktop"
          :url="getImage"
          :big-url="getImage"
          :scroll="true"
          :scale="2.5"
        />
        <img class="hk-mobile" :src="product.thumbnail" />
        <i class="iconfont icon-larger" />
        <hk-preview ref="preview" :data="[product.image]" />
      </div>
      <div class="hk-detail__body">
        <h4>{{ product.type }}</h4>
        <h3>{{ product.name }}</h3>
        <ul class="hk-detail__info">
          <li v-for="(info, index) in product.info" :key="index">{{ info }}</li>
        </ul>
        <ul class="hk-detail__description">
          <li v-for="(description, index) in product.descriptions" :key="index">{{ description }}</li>
        </ul>
        <p class="hk-detail__price">
          <b>HK$</b>
          <span>{{ product.price }} / {{ product.unit }}</span>
          （{{ $t('common.packing') }}：{{ product.package }};
          <em>{{ $t('common.avgPrice') }}：HK$ {{ product.avgPrice }}</em>）
        </p>
        <div v-if="productDetails.discount_detail.length != 0">
          <div v-for="(item, index) in productDetails.discount_detail" :key="index" class="hk-detail__discount">
            <div>
              <p>{{ item.name }}</p>
            </div>
            <div>
              <p>
                <span>{{ item.condition }}</span>
                {{ item.start_time + '到' + item.end_time }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="Boolean(productDetails.group_buy_detail.num)">
          <div class="hk-detail__group-buy">
            <!-- <label>{{ $t('detail.groupBuying') }}</label> -->
            <div>
              <p>{{ productDetails.group_buy_detail.name }}</p>
            </div>
            <div>
              <p>
                <!-- <span>{{ $t('detail.groupByDesc', [productDetails.group_buy_detail.num]) }} HK${{productDetails.group_purchase_price}}</span> -->
                <span>{{ productDetails.group_buy_detail.desc }} HK${{productDetails.group_purchase_price}}</span>
                {{ product.groupBuy.date }}
              </p>
            </div>
          </div>
        </div>
        <div class="hk-detail__amount" v-if="!productDetails.is_sale_out">
          <span>{{ $t('detail.quantity') }}：</span>
          <el-input-number v-model="num" :min="1" :max="product.stock" controls-position="right" />
          <span>{{ $t('common.carton') }}</span>
          <i v-if="product.stock">（{{ this.$t('common.stock', [product.stock]) }}）</i>
          <i
            v-else
            class="hk-detail__stock"
            @click="isDialogShow = true"
          >({{ $t('common.outOfStock') }})</i>
        </div>
        <div class="hk-detail__amount" v-if="productDetails.is_sale_out">
          <i class="hk-detail__stock" @click="isDialogShow = true">({{ $t('common.outOfStock') }})</i>
        </div>
        <div class="hk-detail__button">
          <el-button v-on:click="toCart(num)" type="primary">{{ $t('common.purchase') }}</el-button>
          <el-button v-on:click="toFavourite()">{{ $t('common.favorite') }}</el-button>
        </div>
        <el-collapse v-model="collapse">
          <el-collapse-item :title="$t('detail.contour')">
            <span>{{ product.types }}</span>
            <!---
            <ul>
              <li
                v-for="(type, index) in product.types"
                :key="index"
              >
                {{ type }}
              </li>
            </ul>
            --->
          </el-collapse-item>
        </el-collapse>
        <div class="hk-detail__link">
          <a v-if="productDetails.vedio" @click="goAnimation">
            <i class="iconfont icon-video" />
            <span>{{ $t('detail.animation') }}</span>
          </a>
          <a v-if="productDetails.user_manual" @click="goGuide">
            <i class="iconfont icon-pdf" />
            <span>{{ $t('detail.guide') }}</span>
          </a>
        </div>
      </div>
    </div>
    <ul class="hk-product">
      <li
        v-for="item of products"
        :key="item.id"
        class="hk-product__item"
        @click="goDetail(item.id)"
      >
        <h4>{{ item.category_name }}</h4>
        <h3>{{ item.product_name }}</h3>
        <img :src="item.square_img" />
        <ul>
          <li v-for="(intro, index) in item.introduce" :key="index">{{ intro }}</li>
        </ul>
      </li>
    </ul>

    <el-dialog :visible.sync="isDialogShow">
      <i class="iconfont icon-time" />
      <p>{{ $t('detail.outOfStock') }}</p>
      <el-form label-position="right">
        <el-form-item :label="$t('detail.stockAmount')" required>
          <el-input v-model="stockAmount" :placeholder="$t('detail.stockAmountDesc')" />
        </el-form-item>
      </el-form>
      <div class="hk-dialog__button">
        <el-button @click="isDialogShow = false">{{ $t('common.cancel') }}</el-button>
        <el-button type="primary" @click="handleConfirm()">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="reviewDialogShow">
      <p>{{ $t('common.underReview') }}</p>
      <div class="hk-dialog__button">
        <el-button type="primary" @click="reviewDialogShow = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="addSuccessDialogShow">
      <p>{{ addMessage }}</p>
      <div class="hk-dialog__button">
        <el-button type="primary" @click="addSuccessDialogShow = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
    <!---
    <el-dialog :visible.sync="globalAlert">
      <div><p>{{ $store.state.config.alert.message }}</p></div>
      <div class="hk-dialog__button">
        <el-button type="primary" @click="addSuccessDialogShow = false">{{ $t('common.confirm') }}</el-button>
      </div>
    </el-dialog>
    --->
  </div>
</template>

<script>
import HkPicZoom from 'vue-piczoom'
import HkPreview from '@/components/preview'
export default {
  name: 'HkDetail',
  components: {
    HkPicZoom,
    HkPreview,
  },
  data() {
    //this.getProductDetails();
    return {
      num: 1,
      collapse: [],
      productDetails: {
        all_board_type: '',
        category_id: '',
        category_name: '',
        color: '',
        discount_detail: {},
        gr_avg_price: '',
        group_buy_detail: {},
        group_purchase_price: '',
        id: '',
        introduce: [],
        is_sale_out: '',
        key_word: '',
        or_avg_price: '',
        origin_price: '',
        package: '',
        per_box_price: '',
        product_name: '',
        recommended_board_type: '',
        rectangle_img: '',
        reserve: 0,
        series: {},
        size: '',
        square_img: '',
        unit: '',
        user_manual: '',
        vedio: '',
        weight: '',
      },
      discount: {
        name: 'discount name',
        condition_name: 10,
        send_num: 20,
        price: 10,
        start_date: '',
        end_date: '',
      },
      group_buy: {
        details: 0,
        start_time: '',
        end_time: '',
        price: 0,
      },
      stockAmount: '',
      isDialogShow: false,
      details: false,
      reviewDialogShow: false,
      addSuccessDialogShow: false,
      addMessage: '',
      recommendProduct: [],
      productID: this.$router.history.current.query.id,
    }
  },
  beforeRouteUpdate(to, from, next) {
    // just use `this`
    this.productID = to.query.id
    this.getProductDetails(this.productID)
    next()
  },
  computed: {
    getImage() {
      let self = this
      let url = ''
      if (self.product.image) {
        url = self.product.image
      }
      return url
    },
    async getUpdate() {
      if (!this.details) {
        await this.getProductDetails()
        this.details = true
      }
    },
    description() {
      let des = []
      if (this.productDetails.recommended_board_type) {
        let data = this.productDetails.recommended_board_type
        des.push(data)
      }
      if (this.productDetails.color) {
        let data = this.$t('common.color') + ': ' + this.productDetails.color
        des.push(data)
      }
      if (this.productDetails.size) {
        let data = this.$t('common.size') + ': ' + this.productDetails.size
        des.push(data)
      }
      if (this.productDetails.weight) {
        let data = this.$t('common.weight') + ': ' + this.productDetails.weight
        des.push(data)
      }
      return des
    },
    product() {
      return {
        type: this.productDetails.category_name,
        name: this.productDetails.product_name,
        info: this.productDetails.introduce,
        descriptions: this.description,
        price: this.productDetails.origin_price,
        avgPrice: this.productDetails.or_avg_price,
        package: this.productDetails.package,
        unit: this.productDetails.unit,
        discount: {
          title: this.$t('product.details.discount', [
            this.discount.name,
            this.discount.condition_num,
            this.discount.send_num,
            this.discount.price,
          ]),
          date: this.$t('common.date', [
            this.discount.start_date,
            this.discount.end_date,
          ]),
        },
        groupBuy: {
          title: this.$t('discount.description_general_details', [
            this.group_buy.details,
            this.group_buy.price,
          ]),
          date: this.$t('common.date', [
            this.group_buy.start_time,
            this.group_buy.end_time,
          ]),
        },
        stock: this.productDetails.reserve,
        types: this.productDetails.all_board_type,
        thumbnail: this.productDetails.rectangle_img,
        image: this.productDetails.rectangle_img,
      }
    },
    products() {
      return this.recommendProduct
    },
    globalAlert() {
      return this.$store.state.config.alert.toggle
    },
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        name: 'detail',
        query: { id },
      })
      location.reload()
    },
    goAnimation() {
      let url = this.parseUrl(this.productDetails.vedio)
      window.open(url)
    },
    goGuide() {
      let url = this.parseUrl(this.productDetails.user_manual)
      window.open(url)
    },
    parseUrl(url) {
      let newUrl = window.decodeURIComponent(url)
      newUrl = newUrl.trim().replace(/\s/g, '')

      if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`
      }
      if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`
      }
      return newUrl
    },
    handleConfirm() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/product/arrivalNotice?lang=' +
        self.$store.state.config.language
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      formData = {
        product_id: self.productID,
        num: parseInt(self.stockAmount),
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          switch (response.data.code) {
            case 0:
              self.isDialogShow = false
              self.addMessage = self.$t('common.commandConfirm')
              self.addSuccessDialogShow = true
              break
            case 3007:
              self.isDialogShow = false
              self.addMessage = response.data.msg
              self.addSuccessDialogShow = true
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    previewShow() {
      const el = this.$refs.preview
      el && el.show(0)
    },
    getProductDetails() {
      let self = this
      let productId = self.productID
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi +
        '/api/product/showProd?lang=' +
        self.$store.state.config.language +
        '&product_id=' +
        productId
      this.axios
        .get(apiCall)
        .then(function (response) {
          self.productDetails = response.data.data
          self.discount = {
            name: self.productDetails.discount_detail.name,
            condition_num: self.productDetails.discount_detail.condition_num,
            send_num: self.productDetails.discount_detail.send_num,
            price: self.productDetails.discount_detail.discount_price,
            start_date: self.productDetails.discount_detail.start_time,
            end_date: self.productDetails.discount_detail.end_time,
          }
          self.group_buy = {
            start_time: self.productDetails.group_buy_detail.start_time,
            end_time: self.productDetails.group_buy_detail.end_time,
            price: self.productDetails.group_purchase_price,
          }
          // if (self.productDetails.group_buy_detail.length != 0) {
          //   let c = 0
          //   self.productDetails.group_buy_detail.details.forEach((item) => {
          //     if (c == 0) {
          //       self.group_buy.details = self.$t(
          //         'discount.description_general',
          //         [item.name, item.num]
          //       )
          //       c++
          //     } else {
          //       self.group_buy.details =
          //         self.group_buy.details +
          //         ' + ' +
          //         self.$t('discount.description_general', [item.name, item.num])
          //     }
          //   })
          // }
          /*
          if (self.productDetails.group_buy_detail.length != 0) {
            self.productDetails.group_buy_detail.details.forEach((item) => {
              if (item.product_category_id == self.productDetails.category_id) {
                self.group_buy = {
                  details: item.num,
                  start_time: self.productDetails.group_buy_detail.start_time,
                  end_time: self.productDetails.group_buy_detail.end_time,
                  price: self.productDetails.group_purchase_price,
                }
              }
            })
          }
          */
          self.recommendProduct = response.data.data.series
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    toFavourite() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/product/like?'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let formData = new FormData()
      let productId = self.productID
      formData = {
        product_id: parseInt(productId),
      }
      this.axios
        .post(apiCall, formData, header)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 402:
              self.reviewDialogShow = true
              break
            case 403:
              self.addMessage = self.$t('login.loginFirst')
              self.addSuccessDialogShow = true
              break
            case 0:
              self.addMessage = self.$t('detail.addSuccess')
              self.addSuccessDialogShow = true
              break
            case 3005:
              self.addMessage = self.$t('detail.addAlready')
              self.addSuccessDialogShow = true
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    toCart(num) {
      let self = this
      if (self.productDetails.is_sale_out) {
        self.isDialogShow = true
        self.arrivalProductId = num
      } else {
        let rootApi = self.$store.state.config.apiRoot
        let apiCall = rootApi + '/api/shopping/create?'
        let header = {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        }
        let formData = new FormData()
        let productId = self.productID
        formData = {
          product_id: parseInt(productId),
          num: num,
        }
        this.axios
          .post(apiCall, formData, header)
          .then(function (response) {
            let code = response.data.code
            switch (code) {
              case 402:
                self.reviewDialogShow = true
                break
              case 403:
                self.addMessage = self.$t('login.loginFirst')
                self.addSuccessDialogShow = true
                break
              case 2008:
                self.isDialogShow = true
                self.arrivalProductId = num
                break
              case 0:
                self.updateCart()
                self.addMessage = self.$t('detail.addSuccess')
                self.addSuccessDialogShow = true
                break
              case 3005:
                self.addSuccessDialogShow = true
                self.addMessage = self.$t('detail.addAlready')
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    },
    updateCart() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      this.axios.get(apiCall, header).then(function (response) {
        let num = response.data.data.product_num
        self.$store.commit('updateCartNum', num)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/detail';
</style>
